<template>
  <div v-if="loaded" class="select-jurisdiction">
    <div class="title-container col-md-12 mb-3">
      <h1 class="title">
        Where will (or did) you file your company&nbsp;paperwork?
      </h1>
    </div>

    <div class="description-container col-md-12">
      <p class="description-text">
        Most business owners choose the state where they first conduct business or hold assets.
      </p>
    </div>

    <div class="jurisdiction-selectors-container">
      <vue-multiselect
        class="united-states-dropdown"
        placeholder="-- select state --"
        label="state_province_region"
        :value="jurisdiction"
        :options="jurisdictions"
        :multiple="false"
        :searchable="true"
        @input="onSelectedJurisdictionDropdown($event)"
      />
      <ol id="united-states-map">
        <li
          v-for="index in 99"
          :key="index"
          :class="setStyleForState(index)"
          :title="findStateNameByMapId(index)"
          @click="onSelectedJurisdictionMap(index)"
        >
          <p
            v-if="index in unitedStatesMapped"
            class="state-abbreviation"
          >
            {{ unitedStatesMapped[index].abbreviation }}
          </p>
        </li>
      </ol>
    </div>

    <div class="continue-button-container">
      <span v-if="noJurisdictionSelected" class="button-help-text">
        Select your company home state to continue.
      </span>
      <span v-else>
        Next, let's gather details about your business in {{ jurisdiction.state_province_region }}
      </span>
      <b-button
        :disabled="noJurisdictionSelected"
        class="continue-button"
        variant="primary"
        aria-label="continue button"
        @click="next"
      >
        <span>
          Continue
        </span>
      </b-button>
    </div>
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueMultiselect from 'vue-multiselect'

export default {
  name: 'SelectJurisdiction',
  components: {
    VueMultiselect,
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  computed: {
    ...mapGetters('jurisdictions', [
      'jurisdictions',
      'findByAbbreviation',
      'unitedStatesMapped',
      'findStateNameByMapId',
    ]),
    ...mapGetters('stagelineCreateCompany', [
      'jurisdiction',
      'loaded',
    ]),
    noJurisdictionSelected() {
      return !this.jurisdiction
    },
  },
  async mounted() {
    this.showLoading()
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'setCompanyJurisdiction',
      'setCurrentSlide',
      'setLoaded',
    ]),
    onSelectedJurisdictionDropdown(event) {
      const jurisdictionId = event.id
      if (jurisdictionId) {
        const jurisdiction = this.jurisdictions.find(j => j.id === jurisdictionId)
        this.selectedJurisdiction(jurisdiction)
      }
    },
    onSelectedJurisdictionMap(index) {
      const jurisdiction = this.findByAbbreviation(this.unitedStatesMapped[index].abbreviation)
      this.selectedJurisdiction(jurisdiction)
    },
    selectedJurisdiction(jurisdiction) {
      this.setCompanyJurisdiction(jurisdiction)
    },
    setStyleForState(index) {
      let css = 'state-none'

      if (index in this.unitedStatesMapped) {
        css = 'state-active'

        if (this.unitedStatesMapped[index].abbreviation === this.jurisdiction?.abbreviation) {
          css += ' state-selected'
        }
      }

      return css
    },
    checkSelection(jurisdiction) {
      if (this.jurisdiction) {
        return jurisdiction.abbreviation === this.jurisdiction['abbreviation']
      }
      return false
    },
    showLoading() {
      this.setLoaded(false)
      setTimeout(() => {  this.setLoaded(true) }, 500)
    },
    next() {
      this.setCurrentSlide('SelectEntityType')
    },
  },
}
</script>
<style lang="scss" scoped>

.select-jurisdiction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .title-container, .description-container {
    text-align: center;

    .title, .description-text {
      display: inline-block;
      text-align: left;
    }
  }

  .description-container {
    margin-bottom: 2.5em;
  }

  .jurisdiction-selectors-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    column-gap: 4em;
    justify-content: center;

    ol#united-states-map {
      padding: 0;
      margin: 0;
      list-style: none;

      display: grid;
      grid-template: repeat(9, minmax(37px, 1fr)) / repeat(11, minmax(48px, 1fr));
      grid-gap: 0.4em;

      li {
        width: 100%;
        border-radius: 8px;

        display: flex;
        flex-flow: row nowrap;
        justify-items: center;
        align-items: center;

        p.state-abbreviation {
          width: 100%;
          margin: 0 auto;
          font-size: 0.7em;
        }
      }
    }

    .state-active {
      border: 1px solid $ct-ui-primary;
    }

    .state-active:hover {
      cursor: pointer;
      color: white;
      background: #a5cbca;
    }

    .state-selected {
      color: white;
      background: $ct-ui-primary;
    }

    .united-states-dropdown {
      max-width: 20em;
      padding-left: 1.0em;
      margin-bottom: 4.5em;

      ::v-deep .multiselect__tags {
        border-radius: $stageline-primary-border-radius !important;
      }
    }
  }

  .continue-button-container {
    margin: 6em 0 0 auto;
    justify-content: space-between;

    .continue-button {
      margin-left: 1em;
      width: 8.5em;
    }

    .button-help-text {
      color: #76787A;
    }
  }
}

@media only screen and (max-width: 800px) {
  .jurisdiction-selectors-container {

    ol#united-states-map {
      grid-template: repeat(9, minmax(auto, 1fr)) / repeat(11, minmax(auto, 1fr)) !important;
    }
  }

  .continue-button {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 660px) {
  .jurisdiction-selectors-container {

    ol#united-states-map {

      li {
        border-radius: 8px;

        p.state-abbreviation {
          font-size: 0.8em;
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .jurisdiction-selectors-container {

    ol#united-states-map {
      li {
        border-radius: 4px;

        p.state-abbreviation {
          font-size: 0.7em;
        }
      }
    }
  }
}
</style>
